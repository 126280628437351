import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/FirldTrips/2024/Class6/1.jpg';
// import p2 from 'assests/Photos/FirldTrips/2024/Class6/2.jpg';
// import p3 from 'assests/Photos/FirldTrips/2024/Class6/3.jpg';
// import p4 from 'assests/Photos/FirldTrips/2024/Class6/4.jpg';
// import p5 from 'assests/Photos/FirldTrips/2024/Class6/5.jpg';
// import p6 from 'assests/Photos/FirldTrips/2024/Class6/6.jpg';
// import p7 from 'assests/Photos/FirldTrips/2024/Class6/7.jpg';
// import p8 from 'assests/Photos/FirldTrips/2024/Class6/8.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../CurricularClass52023/components/Sidebar2024';
import Archive_2022 from '../CurricularClass52023/Archive_2022';


const Rocksport2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/12.webp`;
    const p13 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/13.webp`;
    const p14 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/14.webp`;
    const p15 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/15.webp`;
    const p16 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/16.webp`;
    const p17 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/17.webp`;
    const p18 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/18.webp`;
    const p19 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/19.webp`;
    const p20 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/20.webp`;
    const p21 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/21.webp`;
    const p22 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/22.webp`;
    const p23 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/23.webp`;
    const p24 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Rocksport/24.webp`;



    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },
        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
        {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
        },
        {
            src: p17,
            source: p17,
            rows: 1,
            cols: 1,
        },
        {
            src: p18,
            source: p18,
            rows: 1,
            cols: 1,
        },
        {
            src: p19,
            source: p19,
            rows: 1,
            cols: 1,
        },
        {
            src: p20,
            source: p20,
            rows: 1,
            cols: 1,
        },
        {
            src: p21,
            source: p21,
            rows: 1,
            cols: 1,
        },
        {
            src: p22,
            source: p22,
            rows: 1,
            cols: 1,
        },
        {
            src: p23,
            source: p23,
            rows: 1,
            cols: 1,
        },
        {
            src: p24,
            source: p24,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    INHOUSE ADVENTURE CAMP
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 4-5     DATE : 21 September 2024

                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    Rocksport conducted an Adventure Camp for students of Classes 3 and 4 at National Public School,
                                    Yeshwanthpur On 21st September 2024. The camp was designed to provide students with a unique
                                    opportunity to experience adventure-based learning in a safe and engaging environment. The
                                    objective of the camp was to promote physical fitness, teamwork, and self-confidence among the
                                    students through various outdoor activities.
                                    <br />
                                    The camp featured a series of adventurous activities like wall climbing, zip-lining, and team-building
                                    exercises. Each activity was tailored to suit the physical and mental abilities of the children,
                                    ensuring they were both challenging and enjoyable.
                                    <br />
                                    Students developed interpersonal skills through teamwork, collaboration, exposure to real-life
                                    problem-solving scenarios, fostering quick thinking and adaptability.<br />
                                    <br />
                                    The day was filled with energy and excitement as they engaged in various activities. The camp was
                                    a resounding success, achieving its objectives of enhancing physical, mental, and social skills.
                                    <br />

                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Adventure in Action Empowering Young Minds through Outdoor Exploration”
                                <br />
                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive_2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Rocksport2024;